<template>
    <div class="wrap">
        <ul>
            <li v-for="(item, index) in value" :key="index">
                <div class="mask">
                    <i class="el-icon-view" @click="onPreview(item)"></i>
                    <i class="el-icon-delete" @click="onDel(index)"></i>
                </div>
                <img :src="item" alt="">
            </li>
            <li class="last">
                <Upload hideImg v-model="something"></Upload>
            </li>
        </ul>
        <el-dialog title="图片预览" :visible.sync="dialogVisible">
            <div class="dialog">
                <img :src="dialogImageUrl" alt="">
            </div>
        </el-dialog>
    </div>
</template>
<script>
import Upload from './Upload'
export default {
    name: 'MultiGraph',
    components: {
        Upload
    },
    props: {
        value: {
            type: Array,
            default: []
        },
        limit: {
            type: Number,
            default: 10,
        }
    },
    data() {
        return {
            action: this.$Config.http_url + this.$Config.http_api.upload,
            dialogImageUrl: '',
            dialogVisible: false,
            something: '',
        };
    },
    computed: {
        list: {
            get(){
                return this.$props.value
            },
            set(v){
                this.$emit('input', v);
            }
        }
    },
    watch: {
        something(v){
            if(v){
                this.list.push(this.$Config.http_domain + v)
                this.something = ''
            }
        }
    },
    methods: {
        handleAvatarSuccess(file, fileList){
            console.log(file, fileList);
            this.list.push(this.$Config.http_domain + file.data.url.slice(1))
        },
        handleRemove(file, fileList) {
            let url = this.$Config.http_domain + file.response.data.url.slice(1)
            console.log(url);
            this.list = this.list.filter(item => item !== url)
        },
        onPreview(url) {
            this.dialogImageUrl = url;
            this.dialogVisible = true;
        },
        onDel(index){
            this.list.splice(index, 1);
        }
    }
}
</script>

<style lang="scss" scoped>
    .wrap{
        :deep(.el-upload--picture-card){
            width: 128px;
            height: 128px;
            background: #fff;
        }
        :deep(.el-upload-list__item){
            width: 128px;
            height: 128px;
        }
        :deep(.dialog){
            display: flex;
            align-items: center;
            justify-content: center;
            min-height: 300px;
        }
        ul{
            li{
                float: left;
                width: 130px;
                height: 130px;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px dashed #e6e5e5;
                margin-right: 10px;
                border-radius: 6px;
                position: relative;
                transition: .3s;
                overflow: hidden;
                .mask{
                    opacity: 0;
                    position: absolute;
                    inset: 0;
                    background: rgba(#000, .3);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #fff;
                    font-size: 22px;
                    transition: .3s;
                    border-radius: 6px;
                    i:last-child{
                        margin-left: 10px;
                    }
                }
                &:hover{
                    border-color: #409EFF;
                    cursor: pointer;
                    .mask{
                        opacity: 1;
                    }
                }
                &.last{
                    border: none;
                    margin-right: 0;
                }
                img{
                    width: 100%;
                }
            }
        }
    }
    .avatar-uploader-icon {
        // font-size: 28px;
        // color: #8c939d;
       
        // line-height: 128px;
        // text-align: center;
    }
    .avatar {
        width: 128px;
        height: 128px;
        display: block;
    }
</style>