import Vue from 'vue'
import Vuex from 'vuex'
import * as mutations from './mutations'
import * as getters from './getters'
import * as actions from './actions'
Vue.use(Vuex)

const state = {
  scale: false,
  pagesWidth: 1260,
  pagesHeight: 600,
  store_id: null,
  initial_info: {},
  store_info: {},
  store_list: [],
  tagList: [
    { name: '首页', path: '/', url: '/' }
  ],

  menu_list: [],
  initial_menu: [],
  roles_list: [],
}

const store = new Vuex.Store({
  state,
  mutations,
  getters,
  actions,
})

export default store