<template>
    <div id="app" :style="{height: pagesHeight + 'px', width: pagesWidth + 'px'}">
        <router-view />
    </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
    computed: {
        ...mapGetters(["scale"]),
    },
    data() {
        return {
            pagesWidth: 1260,//页面宽度
            pagesHeight: 600,//页面高度
        }
    },
    mounted() {
        this.handleResize()
        window.addEventListener('resize', this.handleResize, true)
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.handleResize);
    },
    methods: {
        handleResize() {
            this.pagesWidth = window.innerWidth
            this.pagesHeight = window.innerHeight
            let metaObj = this.$route.meta
            let minWidth = 1260
            let minHeight = 900

            if (metaObj.father && this.scale) {
                minWidth = minWidth + 190
            }

            if (this.pagesWidth < minWidth) {
                this.pagesWidth = minWidth
            }

            if (this.pagesHeight < minHeight) {
                this.pagesHeight = minHeight
            }
            this.$store.commit('PAGES_WIDTH', this.pagesWidth)
            this.$store.commit('PAGES_HEIGHT', this.pagesHeight)

        },
    },
    watch: {
        scale() {
            this.handleResize()
        },
    },
}
</script>
<style lang="scss">
@import "@/assets/css/common.scss";
@import "@/assets/css/style.scss";
@import "@/assets/css/iconfont.scss";
</style>
