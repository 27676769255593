<template>
    <div class="cover-img" :style="{'--width': `${width}px`}">
        <img :src="url" alt="">
    </div>
</template>


<script>
    export default {
        name: "CoverImg",
        props: ['url', 'width'],
    }
</script>

<style lang="scss">
    .cover-img{
        width: var(--width);
        height: 40px;
        display: flex;
        align-items: center;
        border-radius: 4px;
        overflow: hidden;
        margin: 0 auto;
        img{
            width: 100%;
            object-fit: cover;
        }
    }
</style>