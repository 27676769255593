<template>
    <el-select v-model="modelValue" placeholder="请选择">
        <el-option
        v-for="item in options"
        :key="item.value"
        :label="item.label"
        :value="item.value"
        >
        </el-option>
    </el-select>
</template>
  
<script>
export default {
    inheritAttrs: false,
    name: 'Select',
    props: ['value', 'options'],
    data() {
        return {
            
        }
    },
    computed: {
        modelValue: {
            get() {
                return this.$props.value
            },
            set(v){
                this.$emit('input', v)
            }
        }
    }
}
</script>