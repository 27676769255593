import ApiSelect from './ApiSelect.vue'
import Select from './Select.vue'
import Input from './Input.vue'
import Form from './Form.vue'
import Radio from './Radio.vue'
import Upload from './Upload.vue'
import InputNumber from './InputNumber.vue'
import Table from './Table.vue'
import MultiGraph from './MultiGraph.vue'
import Cascader from './Cascader.vue'
import Dialog from './Dialog.vue'
import DialogForm from './DialogForm.vue'
import CoverImg from './CoverImg.vue'

const components = [
    ApiSelect,
    Select,
    Input,
    Form,
    Cascader,
    Radio,
    Upload,
    InputNumber,
    Table,
    MultiGraph,
    Dialog,
    DialogForm,
    
    CoverImg,
]

const componentPlugin = {
    install(Vue){
        components.forEach(item=>{
            Vue.component(item.name, item)
        })
    }
}
export default componentPlugin
