import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/login', name: 'Login',
    meta: { name: '登录' },
    component: () => import('@/pages/login/login.vue')
  },
  {
    path: '/401', name: '401',
    meta: { name: '无权限' },
    component: () => import('@/pages/home/401.vue')
  },
  {
    path: '/402', name: '402',
    meta: { name: '无菜单' },
    component: () => import('@/pages/home/402.vue')
  },
  {
    path: '/', name: 'Home',
    component: () => import('@/pages/index/index/index.vue'),
    children: [
      {
        path: '/',
        meta: { name: '首页', background: true, father: '/' },
        component: () => import('@/pages/home/home/home.vue')
      },

      {
        path: '/menu', name: 'menu',
        meta: { name: '', },
        component: () => import('@/pages/home/menu.vue')
      },
      // {
      //   path: '/store/stored', name: 'stored',
      //   meta: { name: '储值权益资料', father: 'store' },
      //   component: () => import('@/pages/store/stored.vue'),
      // },

      // {
      //   path: '/store/qualification', name: 'qualification',
      //   meta: { name: '资格卡资料', father: 'store' },
      //   component: () => import('@/pages/store/qualification.vue'),
      // },
      
      {
        path: '/shop/shortcut', name: 'shortcut',
        meta: { name: '快捷菜单', father: 'shop' },
        component: () => import('@/pages/shop/shortcut/index.vue')
      },
      {
        path: '/shop/banner', name: 'banner',
        meta: { name: '轮播图', father: 'shop' },
        component: () => import('@/pages/shop/banner/index.vue')
      },
      {
        path: '/shop/advertising', name: 'advertising',
        meta: { name: '广告位', father: 'shop' },
        component: () => import('@/pages/shop/advertising/index.vue')
      },
      {
        path: '/shop/pagepath', name: 'pagepath',
        meta: { name: '页面路径', father: 'shop' },
        component: () => import('@/pages/shop/pagepath/index.vue')
      },
      {
        path: '/shop/teamwork', name: 'teamwork',
        meta: { name: '多人拼团', father: 'shop' },
        component: () => import('@/pages/shop/teamwork/index.vue')
      },
      {
        path: '/shop/addteamwork', name: 'addteamwork',
        meta: { name: '添加拼团', father: 'shop' },
        component: () => import('@/pages/shop/teamwork/workadd.vue')
      },
      {
        path: '/shop/manage', name: 'manage',
        meta: { name: '商品管理', father: 'shop' },
        component: () => import('@/pages/shop/manage/index.vue')
      },
      {
        path: '/shop/proadd', name: 'proadd',
        meta: { name: '添加商品', father: 'shop' },
        component: () => import('@/pages/shop/manage/proadd.vue')
      },
      {
        path: '/shop/flashsale', name: 'flashsale',
        meta: { name: '限时秒杀', father: 'shop' },
        component: () => import('@/pages/shop/flashsale/index.vue')
      },
      {
        path: '/shop/addflashsale', name: 'addflashsale',
        meta: { name: '添加秒杀', father: 'shop' },
        component: () => import('@/pages/shop/flashsale/saleadd.vue')
      },
      {
        path: '/shop/freight', name: 'freight',
        meta: { name: '运费模版', father: 'shop' },
        component: () => import('@/pages/shop/freight/index.vue')
      },
      {
        path: '/shop/orderDetail', name: 'orderDetail',
        meta: { name: '订单详情', father: 'shop' },
        component: () => import('@/pages/shop/order/detail.vue')
      },
      {
        path: '/shop/order', name: 'order',
        meta: { name: '订单管理', father: 'shop' },
        component: () => import('@/pages/shop/order/index.vue')
      },
      {
        path: '/shop/classification', name: 'classification',
        meta: { name: '商品分类', father: 'shop' },
        component: () => import('@/pages/shop/classification/index.vue')
      },
    ]
  }
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
