<template>
    <div class="el-input-tips">
        <el-input @blur="onBlur" :maxlength="maxlength" v-model="modelValue" :placeholder="placeholder"></el-input>
        <p v-if="tips">{{ tips }}</p>
    </div>
</template>

<script>
export default {
    inheritAttrs: false,
    name: 'Input',
    props: {
        maxlength: {
            type: String,
            default: "100"
        },
        value: {
            type: [String, Number],
            default: ''
        },
        number: {
            type: Boolean,
            default: false
        },
        placeholder: {
            type: String,
            default: '请输入'
        },
        us: {
            type: Boolean,
            default: false
        },
        tips: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            
        }
    },
    methods: {
        onBlur(){
            if(this.$props.us){
                this.$emit('input', Number(this.$props.value).toFixed(2))
            }
        }
    },
    computed: {
        modelValue: {
            get() {
                return this.$props.value
            },
            set(val) {
                if(this.$props.number) {
                    this.$emit('input', val.replace(/[^\d.]+/g, ''))
                } else {
                    this.$emit('input', val)
                }
            }
        }
    }
}
</script>

<style lang="scss">
    .el-input-tips{
        flex-grow: 1;
        p{
            margin-top: 5px;
            font-size: 12px;
            color: #00000072;
        }
    }
</style>