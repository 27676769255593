import store from '../../store'

export function delTagList(url) {
    let tagList = store.state.tagList
    let $index = ''
    tagList.forEach((item,index) => {
        if(item.path == url){
            $index = index
        }
    });
    tagList.splice($index, 1)
    store.commit('TAG_LIST', tagList)
}