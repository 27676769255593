<<template>
    <div>
        <el-dialog
        :title="title"
        :width="`${width}px`"
        :visible.sync="visible"
        :before-close="handleClose"
        :close-on-click-modal="false"
        >
        <slot></slot>
        <span slot="footer" class="dialog-footer">
            <el-button @click="handleClose">取 消</el-button>
            <el-button type="primary" @click="handleConfirm">确 定</el-button>
        </span>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: 'Dialog',
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: '默认标题'
        },
        width: {
            type: Number,
            default: 480
        }
    },
    data() {
        return {
            
        }
    },
    methods: {
        handleClose(){
            this.$emit('onClose', false)
        },
        handleConfirm(){
            this.$emit('onConfirm')
        }
    },
}
</script>